#GalleryWrap {
  background-color: var(--backgroundColor);
  outline: var(--outline);
  box-shadow: inset 0px 0px 20px var(--backgroundColor2);
  border-radius: 30px;
  flex-direction: column;
  margin-block: 24svh;
  display: flex;
}

.titleWrap {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 0;
  display: flex;
}

.ContainerTitle {
  padding: .5em;
  z-index: 5;
  color: var(--mainFontColor);
  font-size: 5.5rem;
  font-family: var(--secondaryFont);
  transform-origin: center;
  background-color: var(--backgroundColor);
  border: var(--outline);
  text-shadow: 0px 0px 40px var(--shadowGlow2High);
  box-shadow: inset 0px 0px 20px var(--backgroundColor2);
  padding-block: .2em;
  border-radius: 30px;
  width: fit-content;
  padding-right: .6em;
  font-weight: 600;
  transition: all .3s;
  position: relative;
  translate: 0% -60%;
}

.Container {
  box-sizing: border-box;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 255px;
  grid-auto-flow: dense;
  z-index: 2;
  background-size: 60%;
  border-radius: 20px;
  gap: 20px;
  width: 100%;
  margin-top: 60px;
  padding: 20px;
  display: grid;
  position: relative;
}

.GalleryContainer {
  animation-range: entry 0% cover 50%;
  animation-name: move-up;
  animation-fill-mode: both;
  animation-timeline: view();
}

.GalleryItem {
  z-index: 2;
  background-size: 350%;
  width: 100%;
  height: 100%;
  transition: all .3s, outline;
  position: relative;
}

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
}

.image:hover {
  outline: var(--outlineGlow);
  box-shadow: 0 0 40px #f5deb380;
}

.image.is-inViewport {
  animation: .4s ease-out forwards fade-up;
}

.GalleryItem .image img {
  object-fit: cover;
  filter: brightness() saturate();
  width: 100%;
  height: 100%;
  transition: all 1s cubic-bezier(.075, .82, .165, 1);
}

.GalleryItem:hover .image img {
  filter: brightness(60%) saturate(.5) blur(2px);
}

.GalleryItem:hover {
  border-radius: 15px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  margin: -10px;
}

.GalleryItem .Text {
  opacity: 0;
  color: #fff;
  letter-spacing: -.015em;
  z-index: 4;
  pointer-events: none;
  text-wrap: nowrap;
  width: 85%;
  font-family: noto sans;
  font-size: 1.75rem;
  font-weight: 600;
  text-decoration: none;
  transition: opacity .5s, bottom .6s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.Text p {
  margin: 0;
}

.GalleryItem:hover .Text {
  opacity: 1;
  bottom: 50%;
}

.Date {
  color: #fff;
  opacity: .5;
  text-shadow: 0 0 20px #00000080;
  font-family: Noto Sans, sans-serif;
  font-weight: 600;
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: 16px;
  right: 20px;
}

.GalleryItem:hover .Date {
  opacity: 1;
}

.w-1 {
  grid-column: span 1;
}

.w-2 {
  grid-column: span 2;
}

.w-3 {
  grid-column: span 3;
}

.w-4 {
  grid-column: span 4;
}

.h-1 {
  grid-row: span 1;
}

.h-2 {
  grid-row: span 2;
}

.h-3 {
  grid-row: span 3;
}
/*# sourceMappingURL=index.39228779.css.map */
