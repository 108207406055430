#GalleryWrap {
  background-color: var(--backgroundColor);
  outline: var(--outline);
  border-radius: 30px;
  /* margin: 5vw; */
  margin-block: 24svh;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0px 0px 20px var(--backgroundColor2);
}

.titleWrap {
  height: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ContainerTitle {
  width: fit-content;
  font-size: 5.5rem;
  padding: 0.5em;
  padding-block: 0.2em;
  padding-right: 0.6em;
  position: relative;
  z-index: 5;
  translate: 0% -60%;
  transition: 0.3s ease;
  color: var(--mainFontColor);
  font-family: var(--secondaryFont);
  font-weight: 600;
  transform-origin: center;
  background-color: var(--backgroundColor);
  /* box-shadow: 0 0 80px var(--shadowGlow), inset 0 0 40px var(--shadowGlow); */
  border: var(--outline);
  border-radius: 30px;
  text-shadow: 0px 0px 40px var(--shadowGlow2High);
  box-shadow: inset 0px 0px 20px var(--backgroundColor2);
}

.Container {
  position: relative;
  background-size: 60%;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* grid-template-rows: repeat(1); */
  padding: 20px;
  grid-auto-rows: 255px;
  grid-auto-flow: dense;
  z-index: 2;
  gap: 20px;
  border-radius: 20px;
  margin-top: 60px;
}

.GalleryContainer {
  animation-name: move-up;
  animation-timeline: view();
  animation-range: entry 0% cover 50%;
  animation-fill-mode: both;
}

.GalleryItem {
  width: 100%;
  height: 100%;
  position: relative;
  transition: 0.3s ease, outline 0s ease;
  background-size: 350%;
  z-index: 2;
}

.image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  overflow: hidden;
  /* display: none; */
}

.image:hover {
  outline: var(--outlineGlow);
  box-shadow: 0px 0px 40px color-mix(in srgb, wheat, transparent);
}

.image.is-inViewport {
  animation: fade-up 0.4s ease-out forwards;
}

.GalleryItem .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  filter: brightness(100%) saturate(1);
}

.GalleryItem:hover .image img {
  filter: brightness(60%) saturate(0.5) blur(2px);
}

.GalleryItem:hover {
  margin: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 15px;
}

.GalleryItem .Text {
  opacity: 0;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 85%;
  color: white;
  font-size: 1.75rem;
  font-family: 'noto sans';
  letter-spacing: -0.015em;
  font-weight: 600;
  z-index: 4;
  transition: opacity 0.5s ease, bottom 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  text-decoration: none;
  pointer-events: none;
  text-wrap: nowrap;
}

.Text p {
  margin: 0;
}

.GalleryItem:hover .Text {
  opacity: 1;
  bottom: 50%;
}

.Date {
  color: white;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 600;
  position: absolute;
  bottom: 16px;
  right: 20px;
  transition: 0.5s ease-in-out;
  opacity: 0.5;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.GalleryItem:hover .Date {
  opacity: 1;
}

.w-1 {
  grid-column: span 1;
}
.w-2 {
  grid-column: span 2;
}
.w-3 {
  grid-column: span 3;
}
.w-4 {
  grid-column: span 4;
}

.h-1 {
  grid-row: span 1;
}
.h-2 {
  grid-row: span 2;
}
.h-3 {
  grid-row: span 3;
}
